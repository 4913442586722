import { graphql, PageProps } from "gatsby";
import * as React from "react";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import App from "../App";
import { isProduction, removeCookie } from "../utils/utils";
import "./index.css";

interface IndexPageProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        siteName: string;
      };
    };
  };
}

const Index: React.FC<IndexPageProps> = ({ data }) => {
  return (
    <>
      <Helmet title={data?.site.siteMetadata.siteName}>
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="theme-color" content="#435562" />
      </Helmet>
      <App />

      {isProduction() && (
        <CookieConsent
          location="top"
          buttonText="Annehmen"
          enableDeclineButton
          cookieName="gatsby-gdpr-google-analytics"
          declineButtonText="Ablehnen"
          buttonStyle={{
            backgroundColor: "#118292",
            textTransform: "uppercase",
            color: "white",
            fontWeight: 600,
          }}
          declineButtonStyle={{
            backgroundColor: "transparent",
            textTransform: "uppercase",
            color: "rgba(255,255,255,0.6)",
            fontWeight: 600,
          }}
          style={{ backgroundColor: "var(--primary-color)", zIndex: 4, color: "white" }}
          sameSite="strict"
          onAccept={() => {
            ReactGA.initialize("UA-174658279-1");
            ReactGA.set({
              page: location.pathname,
              anonymizeIp: true,
            });
            ReactGA.pageview(location.pathname);
          }}
          onDecline={() => {
            removeCookie("_ga");
            removeCookie("_gid");
          }}
          expires={1}
        >
          <p>Wir nutzen Cookies, um einen besseren Service für unsere Kunden zu bieten.</p>
        </CookieConsent>
      )}
    </>
  );
};

export default Index;

export const pageQuery = graphql`
  query SEOData {
    site {
      siteMetadata {
        siteName
      }
    }
  }
`;
