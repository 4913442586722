import * as React from "react";

const LocationsRepresentationToggle: React.FC<{
  list?: React.ReactElement;
  map: React.ReactElement;
}> = ({ list, map }) => {
  const [representation, setRepresentation] = React.useState<"LIST" | "MAP">("MAP");

  const isMap = representation === "MAP";
  const isList = representation === "LIST";

  return (
    <>
      {/* This "hack" is needed because google maps isn't optimized for subsequent re-mounts */}
      <span style={{ display: isMap ? "block" : "none", height: "100%" }}>{map}</span>
      <span
        style={{
          display: isList ? "block" : "none",
          height: "100%",
          overflow: "scroll",
          maxWidth: "100vw",
        }}
      >
        {list}
      </span>
    </>
  );
};

export default LocationsRepresentationToggle;
