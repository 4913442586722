import "@reach/accordion/styles.css";
import * as React from "react";
import { emitSelectionToParentDocument, pluralize } from "../utils/utils";
import { SelectedLocationsCtx } from "./SelectedLocationsProvider";
import style from "./Selection.module.css";

const E_MAIL = process.env.GATSBY_AIRTANGO_MAIL;

const Selection = () => {
  const [selectedLocations] = React.useContext(SelectedLocationsCtx);
  const livepointEl = React.useRef<HTMLSpanElement | null>(null);


  React.useEffect(() => {
    if (!!livepointEl?.current && selectedLocations.length > 0 && !!livepointEl.current.animate) {
      livepointEl.current.animate([{ transform: `scale(1.7)` }, { transform: `scale(1)` }], {
        duration: 400,
        easing: "ease-out"
      });
    }
  }, [selectedLocations]);

  React.useEffect(() => {
    emitSelectionToParentDocument(selectedLocations);
  }, [selectedLocations]);

  const getMailSubject = () => {
    return "Anfrage Werbeplätze";
  };

  return (
    <>
      <div className={style.container} data-test-name="selection">
        <div className={style.selectedLocationsHeader}>
          <p
            style={{
              color: "rgba(255,255,255, 1)",
              marginBottom: "1rem",
              width: "100%",
              textAlign: "center",
              borderBottom: "1px solid var(--white-20)",
              paddingBottom: "0.5rem"
            }}
            className="remove-sizing"
          >
            Ihre aktuelle Auswahl
          </p>
          <p className={`remove-sizing ${style.highlightedMetric}`} data-testId="selection-metrics">
            <span ref={livepointEl}>{selectedLocations.length}</span>
            {pluralize("Livepoint", selectedLocations.length)}
          </p>
        </div>

        <div className={style.buttons}>
          <a
            data-test-name="send-mail"
            href={`mailto:${E_MAIL}?subject=${getMailSubject()}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className={style.mailCta}>
              <svg
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.832 17.581c0 .572-.38.953-.953.953H3.11c-.572 0-.953-.381-.953-.953V6.338c0-.572.381-.953.953-.953h16.77c.572 0 .953.38.953.953V17.58zm-.953-13.34H3.11c-1.143 0-2.096.953-2.096 2.287v11.244c0 1.143.953 2.287 2.096 2.287h16.77c1.144 0 2.097-.953 2.097-2.287V6.528c.19-1.334-.763-2.287-2.097-2.287z" />
                <path
                  d="M11.684 13.389l-6.86-6.29-.763.954 7.242 6.48c.19 0 .19.19.38.19.191 0 .191 0 .382-.19l7.623-6.1-.762-.952-7.242 5.908z" />
              </svg>
              <span>Werbeplätze anfragen</span>
            </button>
          </a>
        </div>
      </div>
    </>
  );
};

export default Selection;
