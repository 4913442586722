import { getUrlOrigin, getWindowWidth } from "../utils/utils";
import { Position } from "../utils/utils.types";
import { LocationWithLatLng } from "./../utils/utils.types";

// coordinates 49.176007, 10.011387
export const AIRTANGO = { lat: 49.174838, lng: 10.010347 };

export const MAP_API_KEY = process.env.GATSBY_GOOGLE_MAPS_API_KEY;

export const MAP_CONFIG: google.maps.MapOptions = {
  zoom: 7,
  center: AIRTANGO,
  gestureHandling: "greedy",
  // disableDefaultUI: true,
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: true,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  noClear: true,
  styles: [
    {
      elementType: "labels.text",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#ffffff",
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative",
      stylers: [
        {
          color: "#17b0c9",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "landscape.man_made",
      stylers: [
        {
          color: "#3d4650",
        },
      ],
    },
    {
      featureType: "landscape.natural",
      stylers: [
        {
          color: "#525a64",
        },
      ],
    },
    {
      featureType: "poi",
      stylers: [
        {
          color: "#566774",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "poi.park",
      stylers: [
        {
          color: "#686f77",
        },
      ],
    },
    {
      featureType: "poi.sports_complex",
      stylers: [
        {
          color: "#686f77",
        },
      ],
    },
    {
      featureType: "road",
      stylers: [
        {
          color: "#3d4650",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.icon",
      stylers: [
        {
          color: "#adadad",
        },
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit",
      stylers: [
        {
          color: "#686f77",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "labels",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "water",
      stylers: [
        {
          color: "#27313d",
        },
      ],
    },
  ],
};

export const getMarkerIcon = (url?: string) => ({
  url: url || `${getUrlOrigin()}/airtango_pin_dark.svg`,
  scaledSize: new google.maps.Size(18, 18 * 1.6),
});

export const getPixelPositionForMarker = (
  map: google.maps.Map,
  marker: google.maps.Marker
): Position => {
  const scale = Math.pow(2, map.getZoom());
  const nw = new google.maps.LatLng(
    map.getBounds()!.getNorthEast().lat(),
    map.getBounds()!.getSouthWest().lng()
  );
  const worldCoordinateNW = map.getProjection()!.fromLatLngToPoint(nw);
  const worldCoordinate = map.getProjection()!.fromLatLngToPoint(marker.getPosition()!);
  const x = {
    x: Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
    y: Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale),
  };
  return x;
};

export const getDetailsHeight = (element: HTMLElement | null, hasImages: boolean): number => {
  if (!!element) {
    return element.getBoundingClientRect().height;
  } else if (!hasImages) {
    return 170;
  } else {
    return 420;
  }
};

export const getDetailsWidth = (element: HTMLElement | null): number => {
  if (!!element) {
    return element.getBoundingClientRect().width;
  }
  return 480;
};

export const getDetailsLeftPosition = (
  mouseX: number,
  element: HTMLDivElement | null,
  mapDiv: HTMLDivElement
) => {
  const detailsWidth = getDetailsWidth(element);
  const optimalPosition = mouseX - detailsWidth / 2; // marker x as center

  // prevent left and right overflow
  // left overflow = result < 0 and small-screen overflow
  if (optimalPosition < 0 || getWindowWidth() <= 450) {
    return 0;
  }
  // right overflow = result < mapWidth
  // right overflow is only important for tablet and mobile layouts
  if (optimalPosition + detailsWidth > mapDiv.getBoundingClientRect().width) {
    return mapDiv.getBoundingClientRect().width - detailsWidth - 50;
  }

  return optimalPosition;
};

export const getDetailsTopPosition = (
  mouseY: number,
  element: HTMLDivElement | null,
  mapDiv: HTMLDivElement,
  hasImages = false
) => {
  const optimalPosition = mouseY - getDetailsHeight(element, hasImages) - 15; // marker height

  if (getWindowWidth() < 450) {
    return 50;
  }
  // top overflow = result < 0;
  if (optimalPosition < 0) {
    return mouseY + 15;
  }

  // bottom overflow = result > windowHeight
  if (optimalPosition > mapDiv.getBoundingClientRect().height) {
    return 500;
  }

  return optimalPosition;
};

export const getDisplayableCircleRadius = (radius: number) => {
  return Math.round(radius);
};
export const roundToNearestHundredth = (radius: number) => {
  return Math.round(radius / 100) * 100;
};

export const meterToKilometer = (meters: number) => {
  return meters / 1000;
};

export const kilometerToMeter = (kilometer: number) => {
  return kilometer * 1000;
};

export const getHighlightedLocations = (
  circle: google.maps.Circle,
  computeDistanceBetween: (a: google.maps.LatLng, b: google.maps.LatLng) => number,
  locations: LocationWithLatLng[]
): LocationWithLatLng[] => {
  if (!circle?.getRadius || locations.length === 0) {
    return [];
  }

  const circleRadius = circle.getRadius();

  return locations.filter((location) => {
    // check if distance between center and location is smaller than radius
    const retVal = computeDistanceBetween(location.geodata, circle.getCenter());
    return retVal < circleRadius;
  });
};
