import { ISelectedLocations, Location, LocationWithLatLng } from "./utils.types";

export const IS_BROWSER = typeof window !== `undefined`;

export const SPRING_CONFIG = {
  mass: 1,
  tension: 700,
  friction: 33,
};

export const IS_MOBILE =
  IS_BROWSER && !window.matchMedia("only screen and (hover: hover)")?.matches;

export const getUrlOrigin = () => window.location.origin;

export const onlyOnEnter = (fn: any) => (e: any) => {
  if (isEnter(e)) {
    fn(e);
  }
};

export const isEnter = (e: any) => e.key === "Enter";

export const getAddress = ({
  street,
  plz,
  city,
}: {
  street: string;
  plz: string;
  city: string;
}) => {
  return `${!!street ? street : "unbekannte Straße"}, ${!!plz ? plz : "0"} ${
    !!city ? city : "unbekannte Stadt"
  }`;
};

export const isHTTP = (hyperlink: string): boolean => !!hyperlink.match(/https?:\/\//);

export const normalizeLink = (hyperlink: string) =>
  isHTTP(hyperlink) ? hyperlink : `http://${hyperlink}`; // let their server handle the redirecting to https

export const getWindowHeight = () => window.innerHeight;

export const getWindowWidth = () => window.innerWidth;

export const pluralize = (str: string, amount: number) =>
  amount > 1 || amount === 0 ? `${str}s` : str;

export const locationsWithLatLngToLocation = (locations: LocationWithLatLng[]): Location[] =>
  locations.map((location) => ({
    ...location,
    geodata: { lat: location.geodata.lat(), lng: location.geodata.lng() },
  }));

export const locationToLocationWithLatLng = (locations: Location[]): LocationWithLatLng[] =>
  locations.map((location) => ({
    ...location,
    geodata: new google.maps.LatLng(location.geodata),
  }));

export const getFormattedScreenCount = (category: "A" | "B"): string =>
  category === "A" ? `mehr als 2` : `1 - 2`;

export const getScreenCount = (category: "A" | "B"): number => (category === "A" ? 3 : 1);

export const trimWhitespace = (str: string): string => str.trim();
export const isDevelopment = () => process.env.NODE_ENV === "development";
export const isProduction = () => process.env.NODE_ENV === "production";
export const removeCookie = (name: string) => {
  document.cookie = `${name}=1; expires=1 Jan 1970 00:00:00 GMT;`;
};

export const getPrice = (category: "A" | "B"): number => {
  if (!category) {
    throw new Error("[getPrice] Category isn't defined!");
  }
  const priceCategoryA = process.env.GATSBY_PRICING_CATEGORY_A;
  const priceCategoryB = process.env.GATSBY_PRICING_CATEGORY_B;
  if (!priceCategoryA || !priceCategoryB) {
    throw new Error(
      "[getPrice] You need to set the environment variables GATSBY_PRICING_CATEGORY_A and GATSBY_PRICING_CATEGORY_B"
    );
  }
  return category === "A" ? +priceCategoryA : +priceCategoryB;
};

export const emitSelectionToParentDocument = (selectedLocations: ISelectedLocations) => {
  if (IS_BROWSER) {
    window.parent.postMessage(selectedLocations.toArray(), "*");
  }
};
