import * as React from "react";
import style from "./InfoBox.module.css";

const InfoBox = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className={style.container}>
      {" "}
      <button className={style.btn} onClick={() => setIsOpen((x) => !x)}>
        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path
            d="M11.772 21.943a10.243 10.243 0 0010.285-10.286A10.243 10.243 0 0011.772 1.371 10.243 10.243 0 001.486 11.657a10.243 10.243 0 0010.286 10.286"
            fill="#435563"
          />
          <path
            d="M10.857 5.371H12.8v1.943h-1.943V5.371zM9.83 11.2h1.028v4.457H9.83V17.6h3.885v-1.943H12.8V11.2h.914V9.257H9.83V11.2z"
            fill="#fff"
          />
          <path
            d="M21.714 11.657c0 5.486-4.457 10.057-10.057 10.057A10 10 0 011.6 11.657C1.6 6.171 6.057 1.6 11.657 1.6c5.6 0 10.057 4.571 10.057 10.057zM11.771.343A11.267 11.267 0 00.457 11.657a11.267 11.267 0 0011.314 11.314 11.267 11.267 0 0011.315-11.314C23.086 5.371 17.943.343 11.77.343"
            fill="#fff"
          />
        </svg>
      </button>
      {isOpen && (
        <section className={style.infoSection}>
          <button className="outside-click" onClick={() => setIsOpen(false)} />
          <h4 className={style.heading}>How it works</h4>
          <p>
            Hi, in den folgenden Abschnitten erklären wir dir kurz die airtango livemap. Sie bietet
            interessierten Kunden die Möglichkeit sich einen Überblick über die airtango Livepoints
            zu verschaffen und Werbetreibende können Livepoints auswählen auf denen sie Werbung
            schalten möchten.
          </p>
          <hr className={style.horizontalDivider} />
          <dl>
            <dt>Karten- und Listenansicht</dt>
            <dd>
              Unsere Livepoints kannst du dir in einer handlichen Kartenansicht oder einer
              praktischen Listenansicht anzeigen lassen.
            </dd>

            <dt>Livepoints filtern und auswählen</dt>
            <dd>
              Um dir die für dich relevanten Livepoints aus unserer großen Auswahl anzeigen zu
              lassen, kannst du die Filterfunktion nutzen. Damit wird nur die Darstellung geändert,
              die sich über den Button "Filter zurücksetzen" wieder herstellen lässt.
              <br />
              Wenn du deine Livepoints gefunden hast, kannst du diese anklicken und sie über das
              dann erscheinende Fenster mit "Zur Auswahl hinzufügen" hinzufügen. Alternativ kannst
              du auch einen Umkreis auswählen. Um diesen festzulegen, kannst du ihn entweder manuell
              mit der km Angabe einstellen oder du ziehst an den Rändern des Kreises. Durch
              "Hinzufügen" werden auch diese Livepoints zur Auswahl hinzugefügt.
              <br />
              <b>Tipp:</b> Um zum Beispiel alle Livepoints in Baden-Württemberg schnell auszuwählen,
              kannst du erst im Bundesland Filter "Baden-Württemberg" eingeben und anschliessend mit
              der Umkreisauswahl alle Livepoints hinzufügen.
            </dd>

            <dt>Auswahl</dt>
            <dd>
              Dir werden zu jedem Zeitpunkt die Anzahl deiner ausgewählten Livepoints und deren
              monatlicher Gesamtpreis angezeigt. Du kannst dir nochmal alle ausgewählten Livepoints
              genauer anschauen, indem du auf "Standortauswahl anzeigen" klickst. Dort hast du auch
              die Möglichkeit, Livepoints wieder aus deiner Auswahl zu löschen.
            </dd>

            <dt>Werbeplätze anfragen</dt>
            <dd>
              Wenn du zufrieden mit deiner Auswahl bist, öffent sich mit einem Klick auf
              "Werbeplätze anfragen" automatisch eine E-Mail, in der alle für uns notwendigen
              Informationen stehen. Durch das Versenden der Mail setzt du dich mit uns unverbindlich
              in Verbindung.
            </dd>
          </dl>
          <p>
            Wir freuen uns auf deine Anfrage,
            <br />
            Dein airtango-team
          </p>
        </section>
      )}
    </div>
  );
};

export default InfoBox;
