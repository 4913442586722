import * as React from "react";
import { useCallback, useState } from "react";
import { ISelectedLocations, Location } from "../utils/utils.types";
import { SelectedLocations } from "./selectedLocations";

export const SelectedLocationsCtx = React.createContext<
  [ISelectedLocations, (location: Location) => void, (location: Location) => void, () => void]
>([SelectedLocations({}), (x: any) => {}, (x: any) => {}, () => {}]);

const SelectedLocationsProvider: React.FC = ({ children }) => {
  const [selectedLocations, setSelectedLocations] = useState<ISelectedLocations>(
    SelectedLocations({})
  );

  const toggleLocationSelection = useCallback(
    (location: Location) => setSelectedLocations(toggleSelectionForLocation(location)),
    [setSelectedLocations]
  );

  const selectLocation = useCallback(
    (location: Location) =>
      setSelectedLocations((sL) => (!sL.includes(location) ? sL.add(location) : sL)),
    [setSelectedLocations]
  );

  const removeAllSelectedLocations = useCallback(
    () => setSelectedLocations(SelectedLocations({})),
    [setSelectedLocations]
  );

  const toggleSelectionForLocation = (location: Location) => (
    selectedLocations: ISelectedLocations
  ): ISelectedLocations =>
    selectedLocations.includes(location)
      ? selectedLocations.remove(location)
      : selectedLocations.add(location);

  return (
    <>
      <SelectedLocationsCtx.Provider
        value={[
          selectedLocations,
          toggleLocationSelection,
          selectLocation,
          removeAllSelectedLocations,
        ]}
      >
        {children}
      </SelectedLocationsCtx.Provider>
    </>
  );
};

export default SelectedLocationsProvider;
