import { getScreenCount } from "../utils/utils";
import { ISelectedLocations, Location } from "../utils/utils.types";

export const SelectedLocations = (newValues: { [key: string]: Location }): ISelectedLocations => ({
  values: { ...newValues },
  get length() {
    return Object.keys(newValues).length;
  },
  get screens() {
    return Object.entries(newValues).reduce(
      (acc, [key, value]) => acc + getScreenCount(value.category),
      0
    );
  },
  get price() {
    return Object.entries(newValues).reduce((acc, [, value]) => acc + value.price, 0);
  },
  map: (mappingFn) =>
    SelectedLocations(
      Object.keys(newValues).reduce(
        (acc, key, index) => ({
          ...acc,
          [key]: mappingFn(newValues[key], index),
        }),
        {}
      )
    ),
  filter: (predicateFn) =>
    SelectedLocations(
      Object.keys(newValues).reduce(
        (acc, key, index) =>
          predicateFn(newValues[key], index) ? { ...acc } : { ...acc, [key]: newValues[key] },
        {}
      )
    ),
  add: (location) => {
    if (!location || !location._id) {
      return SelectedLocations({ ...newValues });
    }
    return SelectedLocations({
      ...newValues,
      [location._id]: location,
    });
  },
  remove: (location) => {
    if (!location || !location._id) {
      return SelectedLocations({ ...newValues });
    }
    const copy = { ...newValues };
    delete copy[location._id];
    return SelectedLocations({ ...copy });
  },
  includes: (location) => newValues.hasOwnProperty(location._id),
  toArray: () => Object.values(newValues),
});
