import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import * as React from "react";
import { onlyOnEnter, trimWhitespace } from "../../utils/utils";
import { CountryCode, Location } from "../../utils/utils.types";
import { createFilterAction, FilterAction, matchRegex } from "../filterLocationsReducer";
import style from "./CountryInput.module.css";

const COUNTRIES = [CountryCode.DE, CountryCode.CH, CountryCode.AT];

const CountryInput: React.FC<{
  doFilter: (
    filterActionCallback: <T extends keyof Location>(inputValue: string, key: T) => FilterAction
  ) => (e: React.FormEvent<HTMLInputElement>) => void;
}> = ({ doFilter }) => {
  const [results, setResults] = React.useState<string[]>([]);

  const handleComboboxConfirmation = doFilter((inputValue, key) =>
    createFilterAction(key)((country) =>
      matchRegex(new RegExp(trimWhitespace(inputValue), "i"))(country as string)
    )
  );

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setResults(
      COUNTRIES.filter((state) => matchRegex(new RegExp(e.currentTarget.value, "i"))(state))
    );
  };

  return (
    <>
      <label htmlFor="country">Land</label>
      <Combobox aria-label="Land auswählen">
        <ComboboxInput
          className={style.input}
          id="country"
          autoComplete="off"
          placeholder="z.B. DE"
          onChange={handleChange}
          onKeyUp={onlyOnEnter(handleComboboxConfirmation)}
          onBlur={handleComboboxConfirmation}
          form="form-filters"
        />
        <ComboboxPopover className={style.comboboxPopover}>
          {results.length > 0 ? (
            <ComboboxList className={style.comboboxList}>
              {results.map((state, index) => (
                <ComboboxOption className={style.comboboxOption} key={index} value={state} />
              ))}
            </ComboboxList>
          ) : (
            <span className={style.notFound}>Nicht gefunden.</span>
          )}
        </ComboboxPopover>
      </Combobox>
    </>
  );
};

export default React.memo(CountryInput);
