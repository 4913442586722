import React from "react";
import { useLocations } from "./data/useLocations";
import LocationFilter from "./Filter/LocationFilter";
import Layout from "./Layout/Layout";
import LocationList from "./LocationList/LocationList";
import Map from "./Map/Map";
import SelectedLocationsProvider from "./Selection/SelectedLocationsProvider";
import Selection from "./Selection/Selection";
import { Location } from "./utils/utils.types";

const App = () => {
  const locations = useLocations();
  const [filteredLocations, setFilteredLocations] = React.useState<Location[]>([]);
  const [showCircleSelection, setShowCircleSelection] = React.useState(false);

  return (
    <div className="app">
      <SelectedLocationsProvider>
        <Layout
          filter={
            <LocationFilter
              locations={locations}
              setFilteredLocations={setFilteredLocations}
              setShowCircleSelection={setShowCircleSelection}
            />
          }
          map={
            <Map
              locations={filteredLocations}
              circleSelectionControls={[showCircleSelection, setShowCircleSelection]}
            />
          }
          list={<LocationList filteredLocations={filteredLocations} />}
          selection={<Selection />}
        />
      </SelectedLocationsProvider>
    </div>
  );
};

export default App;
