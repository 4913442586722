import * as React from "react";
import InfoBox from "../utils/InfoBox/InfoBox";
import style from "./Layout.module.css";
import LocationsRepresentationToggle from "./LocationRepresentationToggle/LocationRepresentationToggle";

const Layout: React.FC<{
  filter: React.ReactElement;
  list: React.ReactElement;
  map: React.ReactElement;
  selection: React.ReactElement;
}> = ({ filter, list, map, selection }) => {
  return (
    <>
      <h1 className={style.heading}>
        <svg
          style={{ marginRight: "1rem" }}
          width="12"
          height="16"
          viewBox="0 0 27 43"
          fill="currentColor"
        >
          <path
            d="M9.938 19.313l9.75-5.25-9.75-5.438 1.687 5.438-1.688 5.25zM24 13.875c0 .188 0 .188 0 0 0 6.938-4.313 12-10.688 13.688v-3.188c-5.624-.188-9.937-4.688-9.937-10.313C3.375 8.25 8.063 3.75 13.688 3.75 19.311 3.562 24 8.25 24 13.875zm3-.375C27 6 21 0 13.5 0S0 6 0 13.5c0 12.563 13.5 28.875 13.5 28.875S27 26.625 27 13.5z"
            fill="#fff"
          />
        </svg>
        Livemap
      </h1>
      <main className={style.layout}>
        <div className={style.info}>
          <InfoBox />
        </div>
        <section className={style.description}>
          <h2>
            Wählen Sie die perfekten <span className={style.highlightPrimary}>Livepoint-</span>
            Standorte!
          </h2>
          <p>Definieren Sie eine Zielregion oder verwenden Sie unsere Umkreisauswahl.</p>
        </section>
        <section className={style.filter}>{filter}</section>
        <section className={style.locationsRepresentation}>
          <LocationsRepresentationToggle map={map} />
        </section>
        <section className={style.selection}>{selection}</section>
      </main>
    </>
  );
};

export default Layout;
