import { FileSystemNode } from "gatsby-source-filesystem";

export type MappingFn<T> = (value: T, index: number) => T;
export type PredicateFn<T> = (item: T) => boolean;
export type Position = { x: number; y: number };

export interface Location {
  readonly _id: string;
  readonly provider: string;
  readonly name: string;
  readonly street: string;
  readonly zip: string;
  readonly state: string;
  readonly city: string;
  readonly country: CountryCode;
  readonly website: string;
  readonly category: "A" | "B";
  readonly price: number;
  readonly geodata: LatLng;
  readonly displayImages: FileSystemNode & { childImageSharp: { fluid: any } }[];
}

export interface LocationWithLatLng {
  readonly _id: string;
  readonly provider: string;
  readonly name: string;
  readonly street: string;
  readonly zip: string;
  readonly state: string;
  readonly city: string;
  readonly country: CountryCode;
  readonly website: string;
  readonly category: "A" | "B";
  readonly price: number;
  readonly geodata: google.maps.LatLng;
  readonly displayImages: FileSystemNode & { childImageSharp: { fluid: any } }[];
}

export interface ISelectedLocations {
  readonly values: { [key: string]: Location };
  includes: (location: Location) => boolean;
  length: number;
  screens: number;
  price: number;
  map: (mappingFn: MappingFn<Location>) => ISelectedLocations;
  add: (location: Location) => ISelectedLocations;
  remove: (location: Location) => ISelectedLocations;
  filter: (predicateFn: (x: Location, index: number) => boolean) => ISelectedLocations;
  toArray: () => Location[];
}
export type LatLng = { lat: number; lng: number };
export enum CountryCode {
  DE = "DE",
  AT = "AT",
  CH = "CH",
}
