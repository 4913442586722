import {
  Dispatch,
  Reducer,
  ReducerAction,
  ReducerState,
  useCallback,
  useReducer,
} from "react";

export const identityFn = <T extends any>(x: T): T => x;
export const useReducerWithDeps = <R extends Reducer<any, any>, I>(
  reducer: R,
  initialArgs: I,
  initializer: (arg: I) => ReducerState<R>,
  deps: any[]
): [ReducerState<R>, Dispatch<ReducerAction<R>>] => {
  const memoizedReducer = useCallback(reducer, deps);
  const [state, dispatch] = useReducer(
    memoizedReducer,
    initialArgs,
    initializer
  );

  return [state, dispatch];
};
