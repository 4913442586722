import debounce from "lodash.debounce";
import * as React from "react";
import { identityFn } from "../utils/useReducerWithDeps";
import { Location, LocationWithLatLng } from "../utils/utils.types";
import { getDisplayableCircleRadius, getHighlightedLocations } from "./mapUtils";

type State = {
  highlightedLocations: LocationWithLatLng[] | null;
  radius: number;
};
type SetRadiusAction = { type: "SET_RADIUS"; payload: { radius: number } };
type SetHighlightedLocationsAction = {
  type: "SET_HIGHLIGHTED_LOCATIONS";
  payload: { highlightedLocations: LocationWithLatLng[] | null };
};
type Action = SetRadiusAction | SetHighlightedLocationsAction;

export const useCircleSelection = (
  showCircleSelection: boolean,
  map: google.maps.Map | null,
  locations: Location[]
): State & { dispatchRadius: Function } => {
  const [{ highlightedLocations, radius }, dispatch] = React.useReducer<
    React.Reducer<State, Action>,
    State
  >(
    (state: State, action: Action) => {
      switch (action.type) {
        case "SET_RADIUS":
          return { ...state, radius: action.payload.radius };
        case "SET_HIGHLIGHTED_LOCATIONS":
          return {
            ...state,
            highlightedLocations: action.payload.highlightedLocations,
          };
        default:
          return state;
      }
    },
    { highlightedLocations: null, radius: 0 },
    identityFn
  );
  const circleRef = React.useRef<google.maps.Circle | null>(null);

  const createDispatchHighlightedLocations = (
    dispatch: React.Dispatch<SetHighlightedLocationsAction>
  ) =>
    debounce(
      (
        circle: google.maps.Circle,
        computeDistanceBetween: (a: google.maps.LatLng, b: google.maps.LatLng) => number,
        transformedLocations: LocationWithLatLng[]
      ) =>
        dispatch({
          type: "SET_HIGHLIGHTED_LOCATIONS",
          payload: {
            highlightedLocations: getHighlightedLocations(
              circle,
              computeDistanceBetween,
              transformedLocations
            ),
          },
        }),
      100
    );

  const dispatchHighlightedLocations = React.useCallback(
    createDispatchHighlightedLocations(dispatch),
    [dispatch]
  );

  const dispatchRadius = React.useCallback(
    (radius: number) =>
      dispatch({
        type: "SET_RADIUS",
        payload: {
          radius,
        },
      }),
    [dispatch]
  );

  React.useEffect(() => {
    if (!!map && showCircleSelection) {
      const transformedLocations: LocationWithLatLng[] = locations.map((location) => ({
        ...location,
        geodata: new google.maps.LatLng(location.geodata),
      }));

      circleRef.current = new google.maps.Circle({
        center: map.getCenter(),
        radius:
          map.getZoom() < 11
            ? Math.pow((20 - map.getZoom()) * 500, 1.2)
            : (20 - map.getZoom()) * 300,
        strokeColor: "#DCDCDC",
        strokeOpacity: 0.8,
        fillColor: "#17afc9",
        fillOpacity: 0.6,
        draggable: true,
        editable: true,
        map,
      });

      const circle = circleRef.current;

      circle.addListener("radius_changed", () => {
        dispatch({
          type: "SET_RADIUS",
          payload: { radius: getDisplayableCircleRadius(circle.getRadius()) },
        });
        dispatchHighlightedLocations(
          circle,
          google.maps.geometry.spherical.computeDistanceBetween,
          transformedLocations
        );
      });

      circle.addListener("center_changed", () => {
        dispatchHighlightedLocations(
          circle,
          google.maps.geometry.spherical.computeDistanceBetween,
          transformedLocations
        );
      });

      dispatch({
        type: "SET_RADIUS",
        payload: {
          radius: getDisplayableCircleRadius(circle.getRadius()),
        },
      });
      dispatch({
        type: "SET_HIGHLIGHTED_LOCATIONS",
        payload: {
          highlightedLocations: getHighlightedLocations(
            circle,
            google.maps.geometry.spherical.computeDistanceBetween,
            transformedLocations
          ),
        },
      });

      return () => {
        circle.setMap(null);
        dispatch({
          type: "SET_HIGHLIGHTED_LOCATIONS",
          payload: { highlightedLocations: null },
        });
      };
    }
  }, [map, locations, dispatch, dispatchHighlightedLocations, showCircleSelection]);

  React.useEffect(() => {
    if (!!circleRef.current && !!radius) {
      circleRef.current.setRadius(radius);
    }
  }, [circleRef, radius]);

  return { highlightedLocations, radius, dispatchRadius };
};
