import { Accordion, AccordionButton, AccordionItem, AccordionPanel } from "@reach/accordion";
import "@reach/accordion/styles.css";
import * as React from "react";
import Pagination from "react-paginate";
import { useLocations } from "../data/useLocations";
import LocationDetails from "../Details/LocationDetails";
import { SelectedLocationsCtx } from "../Selection/SelectedLocationsProvider";
import { Location } from "../utils/utils.types";
import style from "./LocationList.module.css";

const PAGE_SIZE = 10;

const LocationList: React.FC<{
  filteredLocations: Location[];
}> = ({ filteredLocations }) => {
  const locations = useLocations();
  const [selectedLocations, setSelectedLocations] = React.useContext(SelectedLocationsCtx);
  const [paginatedLocations, setPaginatedLocations] = React.useState(
    filteredLocations.slice(0, PAGE_SIZE)
  );
  const [currentPage, setCurrentPage] = React.useState(0);

  React.useEffect(() => {
    const firstItem = currentPage * PAGE_SIZE;
    if (firstItem > filteredLocations.length) {
      setPaginatedLocations(
        filteredLocations.slice(filteredLocations.length - PAGE_SIZE, filteredLocations.length)
      );
    } else {
      setPaginatedLocations(filteredLocations.slice(firstItem, firstItem + PAGE_SIZE));
    }
  }, [filteredLocations, setPaginatedLocations, currentPage]);

  const pageCount = Math.ceil(filteredLocations.length / PAGE_SIZE);
  const handlePageChange = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
  };

  return !locations || locations.length <= 0 ? (
    <p>Keine Livepoints verfügbar.</p>
  ) : (
    <>
      <div className={style.accordionContainer}>
        <div>
          <header className={style.heading}>
            <h4>Verfügbare Standorte</h4>
            <p className={style.hint}>
              Aktuell werden <em>{filteredLocations.length}</em> von <em>{locations.length}</em>{" "}
              Livepoints angezeigt
            </p>
          </header>
        </div>
        <Accordion
          className={style.accordion}
          collapsible
          data-test-name="list-accordion"
          defaultIndex={0}
        >
          {paginatedLocations.length > 0 &&
            paginatedLocations.map((location) => (
              <AccordionItem className={style.accordionItem} key={location._id}>
                <AccordionButton
                  className={`
                  ${style.accordionButton}
                  ${!!selectedLocations.values[location._id] ? style.selectedItem : ""}`}
                >
                  <svg
                    width="14"
                    height="24"
                    viewBox="0 0 27 43"
                    style={{ marginRight: "0.5rem" }}
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9.938 19.313l9.75-5.25-9.75-5.438 1.687 5.438-1.688 5.25zM24 13.875c0 .188 0 .188 0 0 0 6.938-4.313 12-10.688 13.688v-3.188c-5.624-.188-9.937-4.688-9.937-10.313C3.375 8.25 8.063 3.75 13.688 3.75 19.311 3.562 24 8.25 24 13.875zm3-.375C27 6 21 0 13.5 0S0 6 0 13.5c0 12.563 13.5 28.875 13.5 28.875S27 26.625 27 13.5z" />
                  </svg>
                  <span>{location.name} </span>
                  <span className={style.openIndicator}></span>
                </AccordionButton>
                <AccordionPanel className={style.accordionPanel}>
                  <LocationDetails
                    details={location}
                    setSelectedLocations={
                      selectedLocations.includes(location) ? undefined : setSelectedLocations
                    }
                    hideHeading={false}
                  />
                </AccordionPanel>
              </AccordionItem>
            ))}
        </Accordion>
        <div className={style.paginationContainer}>
          <Pagination
            containerClassName={style.pagination}
            nextClassName={style.nextLabel}
            previousClassName={style.previousLabel}
            pageLinkClassName={style.pageLink}
            activeClassName={style.activePageLink}
            disabledClassName={style.disabled}
            pageCount={pageCount}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            onPageChange={handlePageChange}
            nextLabel="weiter"
            previousLabel="zurück"
          />
        </div>
      </div>
    </>
  );
};

export default LocationList;
