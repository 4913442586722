import * as React from "react";
import { animated, useSpring } from "react-spring";
import LocationDetails from "../Details/LocationDetails";
import { SelectedLocationsCtx } from "../Selection/SelectedLocationsProvider";
import { getUrlOrigin, IS_MOBILE, locationsWithLatLngToLocation, SPRING_CONFIG } from "../utils/utils";
import { ISelectedLocations, Location } from "../utils/utils.types";
import style from "./Map.module.css";
import {
  getDetailsLeftPosition,
  getDetailsTopPosition,
  getMarkerIcon,
  getPixelPositionForMarker,
  kilometerToMeter,
  MAP_API_KEY,
  MAP_CONFIG,
  meterToKilometer,
  roundToNearestHundredth
} from "./mapUtils";
import { useCircleSelection } from "./useCircleSelection";
import { useDetailsInfobox } from "./useDetails";

const Map: React.FC<{
  locations: Location[];
  circleSelectionControls: [boolean, (x: boolean) => void];
}> = ({ locations, circleSelectionControls: [showCircleSelection, setShowCircleSelection] }) => {
  const mapDiv = React.useRef<HTMLDivElement | null>(null);
  const detailsRef = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const [selectedLocations, setSelectedLocations, selectLocation] = React.useContext(
    SelectedLocationsCtx
  );
  const [
    details,
    { hideDetails, hoverDetails, clickDetails, reset, setMousePosition }
  ] = useDetailsInfobox(mapDiv);
  const { highlightedLocations, radius, dispatchRadius } = useCircleSelection(
    showCircleSelection,
    map,
    locations
  );

  const { scale } = useSpring({
    scale: details.status === "FIXED" && !!details.location ? 1 : 0,
    from: { scale: 0 },
    config: SPRING_CONFIG
  });

  const handleMarkerMouseOver = React.useCallback(
    (map: google.maps.Map, marker: google.maps.Marker, location: Location) => () => {
      if (!IS_MOBILE && map.getZoom() > 6) {
        hoverDetails(location);
      }
    },
    [hoverDetails]
  );

  const handleMarkerMouseUp = React.useCallback(
    (map: google.maps.Map, marker: google.maps.Marker, location: Location) => () => {
      if (map.getZoom() < 6) {
        return;
      }
      reset();

      setMousePosition(getPixelPositionForMarker(map, marker));
      clickDetails(location);
    },
    [reset, setMousePosition, clickDetails]
  );

  const handleMarkerMouseOut = React.useCallback(
    (
      map: google.maps.Map,
      marker: google.maps.Marker,
      location: Location,
      selectedLocations?: ISelectedLocations
    ) => () => {
      if (!IS_MOBILE) {
        hideDetails(location);
      }

      marker.setIcon(
        getMarkerIcon(
          !!selectedLocations && selectedLocations.includes(location)
            ? `${getUrlOrigin()}/airtango_pin_light.svg`
            : `${getUrlOrigin()}/airtango_pin_dark.svg`
        )
      );
    },

    [hideDetails]
  );

  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${MAP_API_KEY}&libraries=geometry`;
    script.defer = true;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      setMap(new google.maps.Map(mapDiv.current as HTMLElement, MAP_CONFIG));
    };
  }, []);

  React.useEffect(() => {
    let markers: google.maps.Marker[] = [];
    if (!!map && !!locations) {
      // set markers and register click event listeners
      markers = locations.map((location) => {
        return new google.maps.Marker({
          position: location.geodata,
          map,
          icon: getMarkerIcon()
        });
      });

      map.addListener("bounds_changed", reset);

      return () => {
        markers.forEach((m) => m.setMap(null));
        google.maps.event.clearInstanceListeners(map); // clear all markers if component gets destroyed
      };
    }
  }, [
    map,
    locations,
    hideDetails,
    clickDetails,
    hoverDetails,
    setMousePosition,
    reset,
    handleMarkerMouseOut,
    handleMarkerMouseUp,
    handleMarkerMouseOver
  ]);

  React.useEffect(() => {
    let markers: google.maps.Marker[] = [];

    if (!!map && selectedLocations.length > 0) {
      markers = selectedLocations.toArray().map((location) => {
        return new google.maps.Marker({
          position: location.geodata,
          map,
          zIndex: google.maps.Marker.MAX_ZINDEX + 1, // makes sure selected markers are always visible
          icon: getMarkerIcon(`${getUrlOrigin()}/airtango_pin_light.svg`)
        });

      });

      return () => {
        // cleanup for all markers
        markers.forEach((marker) => marker.setMap(null));
      };
    }
  }, [map, selectedLocations, handleMarkerMouseOver, handleMarkerMouseOut]);

  React.useEffect(() => {
    if (!!map && !!highlightedLocations) {
      const highlightedLocationsMarkers = highlightedLocations.map((location) => {
        return new google.maps.Marker({
          position: location.geodata,
          icon: getMarkerIcon(`${getUrlOrigin()}/airtango_pin_light.svg`),
          map,
          zIndex: google.maps.Marker.MAX_ZINDEX
        });
      });

      return () => {
        highlightedLocationsMarkers.forEach((marker) => marker.setMap(null));
        highlightedLocationsMarkers.length = 0;
      };
    }
  }, [map, highlightedLocations]);

  return (
    <>
      {(details.status === "FIXED" || details.status === "VARIABLE") && !!details.location && (
        <animated.div
          className={style.details}
          ref={detailsRef}
          style={{
            position: "absolute",
            left: `${getDetailsLeftPosition(
              details.position.x,
              detailsRef.current,
              mapDiv.current as HTMLDivElement
            )}px`,
            top: `${getDetailsTopPosition(
              details.position.y,
              detailsRef.current,
              mapDiv.current as HTMLDivElement,
              details.location.displayImages?.length > 0
            )}px`,
            zIndex: 999999, // markers can have pretty large z-indices
            transform: IS_MOBILE ? scale.interpolate((x) => `scale(${x})`) : ""
          }}
        >
          {
            <LocationDetails
              details={details.location}
              close={reset}
              setSelectedLocations={
                selectedLocations.includes(details.location)
                  ? undefined
                  : (location) => {
                    setSelectedLocations(location);
                    reset();
                  }
              }
            />
          }
        </animated.div>
      )}
      <div className={style.map} ref={mapDiv} data-testid="map">
        {showCircleSelection && !!highlightedLocations && (
          <div className={style.bottomLegend}>
            <div className={style.dialog}>
              <div className={style.livepointDetails}>
                <span>
                  <span className={style.highlightLocationCount}>
                    {highlightedLocations.length}
                  </span>{" "}
                  Livepoints
                </span>
                <span className={style.radiusInput}>
                  <label htmlFor="radius">
                    im Radius von
                    <input
                      id="radius"
                      type="number"
                      step="0.1"
                      value={meterToKilometer(roundToNearestHundredth(radius))}
                      onChange={(e) => dispatchRadius(kilometerToMeter(+e.target.value))}
                    />
                    km
                  </label>
                </span>
              </div>
              <div className={style.action}>
                <button
                  className={style.dismiss}
                  onClick={() => {
                    setShowCircleSelection(false);
                  }}
                >
                  Abbrechen
                </button>
                <button
                  className={style.select}
                  onClick={() => {
                    locationsWithLatLngToLocation(highlightedLocations).forEach(selectLocation);
                    setShowCircleSelection(false);
                  }}
                >
                  Hinzufügen
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(
  Map,
  ({ locations: prevLocations, ...prevRest }, { locations: currLocations, ...currRest }) => {
    return prevLocations.length === currLocations.length && prevRest === currRest;
  }
);
