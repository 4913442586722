import Img from "gatsby-image";
import * as React from "react";
import CloseButton from "../utils/CloseButton";
import { getAddress, getFormattedScreenCount, normalizeLink } from "../utils/utils";
import { Location } from "../utils/utils.types";
import style from "./LocationDetails.module.css";

const LocationDetails: React.FC<{
  details: Location;
  setSelectedLocations?: (location: Location) => void;
  close?: () => void;
  hideHeading?: boolean;
  hideInfo?: boolean;
}> = ({ details, setSelectedLocations, close, hideHeading, hideInfo }) => {
  return (
    <>
      <div className={style.details}>
        {!hideHeading && (
          <div className={style.heading}>
            <svg
              width="16"
              height="26"
              viewBox="0 0 27 43"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.938 19.313l9.75-5.25-9.75-5.438 1.687 5.438-1.688 5.25zM24 13.875c0 .188 0 .188 0 0 0 6.938-4.313 12-10.688 13.688v-3.188c-5.624-.188-9.937-4.688-9.937-10.313C3.375 8.25 8.063 3.75 13.688 3.75 19.311 3.562 24 8.25 24 13.875zm3-.375C27 6 21 0 13.5 0S0 6 0 13.5c0 12.563 13.5 28.875 13.5 28.875S27 26.625 27 13.5z"
                fill="#fff"
              />
            </svg>
            <h4 className={style.title}>{details.name}</h4>
            {!!close && (
              <div style={{ marginLeft: "auto" }}>
                <CloseButton onClick={close} />
              </div>
            )}
          </div>
        )}
        {!hideInfo && (
          <div>
            <ul className={style.info}>
              <li>
                {getAddress({
                  street: details.street,
                  plz: details.zip,
                  city: details.city,
                })}
              </li>
              <li className={style.screens}>
                <span>
                  <em>{getFormattedScreenCount(details.category)}</em> Screens
                  <span className={style.divider}>/</span>
                </span>
                <span>
                  <em>{details.price}€</em> pro Monat
                </span>
              </li>
              <li className={style.websiteLink}>
                <a href={normalizeLink(details.website)} rel="noopener noreferrer" target="_blank">
                  Zur Website
                </a>
              </li>
            </ul>
          </div>
        )}
        {details.displayImages && details.displayImages.length > 0 ? (
          <ul className={style.imagesContainer}>
            {details.displayImages.map((image, index) => (
              <li key={index} className={style.image}>
                <Img
                  fluid={image.childImageSharp.fluid}
                  alt={`Livepoint ${index} ${details.provider} ${details.name}`}
                />
              </li>
            ))}
          </ul>
        ) : (
          <p className="remove-sizing">Leider sind keine Bilder für diesen Livepoint verfügbar.</p>
        )}
        {!!setSelectedLocations && (
          <button
            type="button"
            data-test-name="details-select"
            className={style.select}
            onClick={() => setSelectedLocations(details)}
          >
            Zur Auswahl hinzufügen
          </button>
        )}
      </div>
    </>
  );
};

export default LocationDetails;
