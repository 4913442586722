import { Dispatch, useCallback, useReducer } from "react";
import { useMouseInformation } from "../utils/useMouseInformation";
import { Location, Position } from "../utils/utils.types";

type Status = "HIDDEN" | "FIXED" | "VARIABLE";
type DetailsState = {
  readonly status: Status;
  readonly position: Position;
  readonly location: Location | null;
};
type HoverAction = {
  readonly type: "HOVER";
  readonly payload: { location: Location };
};
type ClickAction = {
  readonly type: "CLICK";
  readonly payload: { location: Location };
};
type HideAction = {
  readonly type: "HIDE";
  readonly payload: { location: Location };
};
type MousePositionAction = {
  readonly type: "MOUSE_POSITION";
  readonly payload: { position: Position };
};
type ResetAction = {
  readonly type: "RESET";
};
type DetailsActions = HoverAction | ClickAction | HideAction | MousePositionAction | ResetAction;
type DetailsReducer = React.Reducer<DetailsState, DetailsActions>;

const createHoverAction = (dispatch: Dispatch<DetailsActions>) => (location: Location) =>
  dispatch({ type: "HOVER", payload: { location } });
const createClickAction = (dispatch: Dispatch<DetailsActions>) => (location: Location) =>
  dispatch({ type: "CLICK", payload: { location } });
const createHideAction = (dispatch: Dispatch<DetailsActions>) => (location: Location) =>
  dispatch({ type: "HIDE", payload: { location } });
const createMousePositionAction = (dispatch: Dispatch<DetailsActions>) => (position: Position) =>
  dispatch({ type: "MOUSE_POSITION", payload: { position } });
const createResetAction = (dispatch: Dispatch<DetailsActions>) => () => dispatch({ type: "RESET" });

const reducer: DetailsReducer = (state: DetailsState, action: DetailsActions) => {
  if (state.status === "FIXED" && action.type !== "RESET") {
    return state;
  }

  switch (action.type) {
    case "CLICK":
      return { ...state, status: "FIXED", location: action.payload.location };
    case "HOVER":
      return {
        ...state,
        status: "VARIABLE",
        location: action.payload.location,
      };
    case "HIDE":
      return { ...state, status: "HIDDEN", location: null };
    case "RESET":
      return { ...state, status: "HIDDEN" };
    case "MOUSE_POSITION":
      return { ...state, position: action.payload.position };
    default:
      return state;
  }
};

export const useDetailsInfobox = (
  ref: React.RefObject<HTMLElement>
): [
  DetailsState,
  {
    hideDetails: (location: Location) => void;
    hoverDetails: (location: Location) => void;
    clickDetails: (location: Location) => void;
    setMousePosition: (position: Position) => void;
    reset: () => void;
  }
] => {
  const [state, dispatch] = useReducer<DetailsReducer>(reducer, {
    status: "HIDDEN",
    position: { x: 0, y: 0 },
    location: null,
  });

  const hideDetails = useCallback(createHideAction(dispatch), [dispatch]);
  const hoverDetails = useCallback(createHoverAction(dispatch), [dispatch]);
  const clickDetails = useCallback(createClickAction(dispatch), [dispatch]);
  const setMousePosition = useCallback(createMousePositionAction(dispatch), [dispatch]);
  const reset = useCallback(createResetAction(dispatch), [dispatch]);

  useMouseInformation(
    "mousemove",
    (e) => {
      const rect = ref.current?.getBoundingClientRect();
      return setMousePosition({
        x: e.pageX - (rect as any).left,
        y: e.clientY - (rect as any).top,
      });
    },
    ref
  );

  return [
    state,
    {
      hideDetails,
      hoverDetails,
      clickDetails,
      reset,
      setMousePosition,
    },
  ];
};
