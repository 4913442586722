import * as React from "react";

export const useMouseInformation = (
  event: any,
  handler: (e: MouseEvent) => void,
  ref?: React.RefObject<HTMLElement>
) => {
  React.useEffect(() => {
    if (!!ref) {
      const node = ref.current;
      node?.addEventListener(event, handler, { passive: true });
      return () => node?.removeEventListener(event, handler);
    } else {
      window.addEventListener(event, handler, { passive: true });
      return () => window.removeEventListener(event, handler);
    }
  }, [ref, event, handler]);
};
