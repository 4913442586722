import { graphql, useStaticQuery } from "gatsby";
import { Location } from "../utils/utils.types";
import { getPrice } from "../utils/utils";

const createLocation = (obj: any): Location => ({
  _id: obj["_id"],
  provider: obj["provider"],
  name: `${obj["provider"]} ${obj["name"]}`,
  zip: obj["zip"],
  street: obj["street"],
  city: obj["city"],
  state: obj["state"],
  country: obj["country"],
  website: obj["website"],
  category: obj["category"],
  price: getPrice(obj["category"]),
  geodata: obj["geodata"],
  displayImages: obj["displayImages"],
});

const LOCATIONS_QUERY = graphql`
  query livepoints {
    allLivepoint {
      nodes {
        id
        _id
        provider
        name
        street
        zip
        state
        city
        country
        website
        category
        geodata {
          lat
          lng
        }
      }
    }
  }
`;

export const useLocations = () => {
  const locationResponse = useStaticQuery<{
    allLivepoint?: { nodes: Location[] };
    errors?: any[];
  }>(LOCATIONS_QUERY);

  if (
    (!!locationResponse?.errors && locationResponse?.errors.length > 0) ||
    !locationResponse?.allLivepoint
  ) {
    return [];
  }
  return locationResponse.allLivepoint.nodes.map(createLocation);
};
