import { logFilterAppliedEvent } from "./../data/analytics";
import { Location, PredicateFn } from "../utils/utils.types";

/*
  
  {
    locations: [Lcoation1, Location2...],
    filters: Map({key, filter}),
    filteredLocations: subset of locations
  }

*/

export type State = {
  readonly locations: Location[];
  readonly filters: FilterMap;
  readonly filteredLocations: Location[];
};

export type FilterMap = Map<keyof Location, PredicateFn<Location[keyof Location]>>;

export type Filter<T> = {
  key: keyof T;
  filter: PredicateFn<T[keyof T]>;
};

export type FilterAction = {
  readonly type: "FILTER";
  readonly payload: Filter<Location>;
};

export type ResetFilterAction = {
  readonly type: "RESET_FILTER";
};

export type RemoveFilterAction = {
  readonly type: "REMOVE_FILTER";
  readonly payload: { key: keyof Location };
};

export type Action = FilterAction | RemoveFilterAction | ResetFilterAction;

export type FilterActionFn<T> = <R extends keyof T>(
  key: R
) => (filter: PredicateFn<T[keyof T]>) => FilterAction;

export const createResetFilterAction = (): ResetFilterAction => ({
  type: "RESET_FILTER",
});

export const createFilterAction: FilterActionFn<Location> = (key) => (filter) => ({
  type: "FILTER",
  payload: { key, filter },
});

export const createRemoveFilterAction = (key: keyof Location): RemoveFilterAction => ({
  type: "REMOVE_FILTER",
  payload: { key },
});

export const matchRegex = (regex: RegExp) => (string: string): boolean => {
  const result = string.match(regex);
  return !!result && result.length > 0;
};

const applyAllFilters = (locations: Location[]) => (filters: FilterMap) => {
  return Array.from(filters).reduce(
    (filteredLocations, [key, filter]) => filteredLocations.filter((l) => filter(l[key])),
    locations
  );
};

/* 

  REDUCER

*/
export const filterableListReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "FILTER":
      const newFilters = new Map(Array.from(state.filters)).set(
        action.payload.key,
        action.payload.filter
      );
      const filteredLocations = applyAllFilters(state.locations)(newFilters);
      return {
        ...state,
        filters: newFilters,
        filteredLocations,
      };
    case "REMOVE_FILTER":
      return {
        ...state,
        filters: new Map(Array.from(state.filters).filter(([key]) => key !== action.payload.key)),
      };
    case "RESET_FILTER":
      return {
        ...state,
        filters: new Map(),
        filteredLocations: [...state.locations],
      };
    default:
      return {
        ...state,
      };
  }
};
