import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import * as React from "react";
import { onlyOnEnter, trimWhitespace } from "../../utils/utils";
import { CountryCode, Location } from "../../utils/utils.types";
import { createFilterAction, FilterAction, matchRegex } from "../filterLocationsReducer";
import style from "./StateInput.module.css";

const STATES: { [key: string]: string[] } = {
  [CountryCode.DE]: [
    "Baden-Württemberg",
    "Bayern",
    "Berlin",
    "Brandenburg",
    "Bremen",
    "Hamburg",
    "Hessen",
    "Mecklenburg-Vorpommern",
    "Niedersachsen",
    "Rheinland-Pfalz",
    "Nordrhein-Westfalen",
    "Saarland",
    "Sachsen",
    "Sachsen-Anhalt",
    "Schleswig-Holstein",
    "Thüringen",
  ],
  [CountryCode.CH]: [
    "Graubünden",
    "Bern",
    "Wallis",
    "Waadt",
    "Tessin",
    "St. Gallen",
    "Zürich",
    "Freiburg",
    "Luzern",
    "Aargau",
    "Uri",
    "Thurgau",
    "Schwyz",
    "Jura",
    "Neuenburg",
    "Solothurn",
    "Glarus",
    "Basel-Landschaft",
    "Obwalden",
    "Schaffhausen",
    "Genf",
    "Nidwalden",
    "Appenzell",
    "Zug",
    "Appenzell",
    "Basel-Stadt",
  ],
  [CountryCode.AT]: [
    "Burgenland",
    "Kärnten",
    "Niederösterreich",
    "Oberösterreich",
    "Salzburg",
    "Steiermark",
    "Tirol",
    "Vorarlberg",
    "Wien",
  ],
};

const ALL_STATES = Object.keys(STATES).reduce(
  (acc: string[], curr: string) => acc.concat(STATES[curr]),
  [] as string[]
);

const StateInput: React.FC<{
  doFilter: (
    filterActionCallback: <T extends keyof Location>(inputValue: string, key: T) => FilterAction
  ) => (e: React.FormEvent<HTMLInputElement>) => void;
}> = ({ doFilter }) => {
  const [results, setResults] = React.useState<string[]>([]);

  const handleComboboxConfirmation = doFilter((inputValue, key) =>
    createFilterAction(key)((state) =>
      matchRegex(new RegExp(`^${trimWhitespace(inputValue)}$`, "i"))(state as string)
    )
  );

  const handleChange = React.useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setResults(
        ALL_STATES.filter((state) => matchRegex(new RegExp(e.currentTarget.value, "i"))(state))
      );
    },
    [setResults]
  );

  return (
    <>
      <label htmlFor="state">Bundesland</label>
      <Combobox aria-label="Bundesland auswählen">
        <ComboboxInput
          className={style.input}
          id="state"
          autoComplete="off"
          placeholder="z.B. Baden-Württemberg"
          onChange={handleChange}
          onKeyUp={onlyOnEnter(handleComboboxConfirmation)}
          onBlur={handleComboboxConfirmation}
          form="form-filters"
        />
        <ComboboxPopover className={style.comboboxPopover}>
          {results.length > 0 ? (
            <ComboboxList className={style.comboboxList}>
              {results.map((state, index) => (
                <ComboboxOption className={style.comboboxOption} key={index} value={state} />
              ))}
            </ComboboxList>
          ) : (
            <span className={style.notFound}>Nicht gefunden.</span>
          )}
        </ComboboxPopover>
      </Combobox>
    </>
  );
};

export default React.memo(StateInput);
