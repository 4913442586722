import * as React from "react";
import { identityFn, useReducerWithDeps } from "../utils/useReducerWithDeps";
import { getWindowWidth, isEnter, IS_MOBILE, trimWhitespace } from "../utils/utils";
import { Location } from "../utils/utils.types";
import CountryInput from "./CountryInput/CountryInput";
import {
  Action,
  createFilterAction,
  createRemoveFilterAction,
  createResetFilterAction,
  filterableListReducer,
  FilterAction,
  matchRegex,
  State,
} from "./filterLocationsReducer";
import style from "./LocationFilter.module.css";
import StateInput from "./StateInput/StateInput";

const LocationFilter: React.FC<{
  locations: Location[];
  setFilteredLocations: React.Dispatch<React.SetStateAction<Location[]>>;
  setShowCircleSelection: Function;
}> = ({ locations, setFilteredLocations, setShowCircleSelection }) => {
  const [localLocations, dispatch] = useReducerWithDeps<React.Reducer<State, Action>, State>(
    filterableListReducer,
    { locations, filters: new Map(), filteredLocations: locations },
    identityFn,
    [locations]
  );

  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    setFilteredLocations(localLocations.filteredLocations);
  }, [localLocations, setFilteredLocations]);

  const handleFilterConfirmation = React.useCallback(
    <T extends keyof Location>(key: T) => (
      filterActionCallback: (inputValue: string, key: T) => FilterAction
    ) => (e: React.FormEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value;
      dispatch(filterActionCallback(value, key));
      !value && dispatch(createRemoveFilterAction(key));
    },
    [dispatch]
  );

  const withEventHandling = (action: Function) => (event: any) => {
    if (event.nativeEvent instanceof KeyboardEvent && isEnter(event)) {
      action(event);
    } else if (event.nativeEvent instanceof FocusEvent) {
      action(event);
    }
  };

  const handleNameConfirmation = withEventHandling(
    handleFilterConfirmation("name")((inputValue, key) =>
      createFilterAction(key)((name) =>
        matchRegex(new RegExp(trimWhitespace(inputValue), "i"))(name as string)
      )
    )
  );

  const handleZipConfirmation = withEventHandling(
    handleFilterConfirmation("zip")((inputValue, key) =>
      createFilterAction(key)((zip) =>
        matchRegex(new RegExp(trimWhitespace(inputValue), "i"))(zip.toString())
      )
    )
  );

  const handleCityConfirmation = withEventHandling(
    handleFilterConfirmation("city")((inputValue, key) =>
      createFilterAction(key)((city) =>
        matchRegex(new RegExp(trimWhitespace(inputValue), "i"))(city as string)
      )
    )
  );

  const handleCountryConfirmation = React.useCallback(handleFilterConfirmation("country"), [
    handleFilterConfirmation,
  ]);

  const handleStateConfirmation = React.useCallback(handleFilterConfirmation("state"), [
    handleFilterConfirmation,
  ]);

  const content = (
    <>
      <div className={style.inputs}>
        <div className={style.zip}>
          <label htmlFor="zip">PLZ</label>
          <input
            id="zip"
            className={style.zipInput}
            type="number"
            placeholder="z.B. 74564"
            onKeyUp={handleZipConfirmation}
            onBlur={handleZipConfirmation}
          />
        </div>

        <div className={style.city}>
          <label htmlFor="city">Stadt</label>
          <input
            id="city"
            className={style.cityInput}
            type="text"
            placeholder="z.B. Crailsheim"
            onKeyUp={handleCityConfirmation}
            onBlur={handleCityConfirmation}
          />
        </div>

        <div className={style.country}>
          <CountryInput doFilter={handleCountryConfirmation} />
        </div>

        <div className={style.state}>
          <StateInput doFilter={handleStateConfirmation} />
        </div>

        <div className={style.name}>
          <label htmlFor="name">Livepoint Name</label>
          <input
            id="name"
            type="text"
            placeholder="z.B. Clever Fit Crailsheim"
            onKeyUp={handleNameConfirmation}
            onBlur={handleNameConfirmation}
          />
        </div>
      </div>

      <div className={style.controls}>
        <button
          type="reset"
          className={style.reset}
          onClick={() => dispatch(createResetFilterAction())}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 12 11"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2.6 3.2l-.1.2c-.2.2-.5.3-.8.1l-.2-.1c-.1-.1-.2-.2-.2-.4 0-.1 0-.3.1-.4l.1-.2c.1-.1.3-.2.4-.2.1 0 .3 0 .4.1l.2.1c.1.1.2.2.2.4.1.1 0 .3-.1.4zm8.1-1.1C9.8.9 8.5.2 7.1.1 6.3 0 5.4.1 4.7.4c-.8.3-1.4.7-2 1.3-.5-.3-1.1-.2-1.5.2l-.2.2c-.2.2-.3.5-.3.9 0 .3.2.6.4.8l.2.1c.2.2.5.3.8.3.3 0 .7-.1.9-.4l.1-.2c.2-.2.3-.5.3-.9 0-.2-.1-.4-.2-.6.5-.5 1.1-.9 1.8-1.2.1 0 .2-.1.3-.1h.1c.4-.1.8-.1 1.2-.1h.6c1 .2 1.9.7 2.7 1.5l.1.1c.2.2.4.5.5.8.2.4.3.8.4 1.2v.1c.2 1.1-.1 2.3-.7 3.3l-.2.2-.1.1c-.7.8-1.5 1.3-2.4 1.5-.3.1-.5.1-.8.1-.3 0-.6 0-.9-.1-.2 0-.4-.1-.6-.2-.7-.2-1.3-.6-1.8-1.1 0 0 0-.1-.1-.1-.2-.2-.4-.2-.6 0-.2.2-.1.3 0 .5.6.7 1.4 1.2 2.3 1.4.8.2 1.6.3 2.4.1 1.3-.2 2.5-1 3.3-2.1.5-.7.8-1.6.9-2.4.2-1.1-.1-2.4-.9-3.5z" />
            <path d="M7.9 3.3L6.6 4.7 5.2 3.3l-.5.5 1.4 1.4-1.4 1.4.5.5 1.4-1.4 1.3 1.4.5-.5-1.3-1.4 1.3-1.4-.5-.5z" />
          </svg>
          <span>Filter zurücksetzen</span>
        </button>

        <button
          type="button"
          onClick={() => {
            setShowCircleSelection(true);
            setIsOpen(false);
          }}
          className={style.circleSelection}
        >
          <svg
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 14 14"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7.3.9c1.6 0 3.1.6 4.2 1.8 1.1 1.2 1.8 2.7 1.8 4.3 0 1.6-.6 3.2-1.8 4.3-1.1 1.2-2.7 1.8-4.3 1.8-1.6 0-3.1-.6-4.3-1.8-1.1-1.2-1.8-2.7-1.8-4.4 0-1.6.6-3.2 1.8-4.3C4.1 1.5 5.7.9 7.3.9zM4.9.8C2.5 1.8 1 3.7.7 6.3v1.2c0 .2.1.4.1.6.1.5.2.9.3 1.3 1 2.5 2.9 3.9 5.5 4.2 3.6.4 6.7-2.1 7.2-5.7V6.5c0-.2-.1-.4-.1-.6-.1-.5-.2-.9-.3-1.3C12.4 2.2 10.6.7 8 .4H6.8c-.2 0-.4.1-.6.1-.5 0-.9.1-1.3.3" />
            <path d="M7.3 5.8c.3 0 .6.1.8.4.2.2.3.6.3.9 0 .6-.6 1.2-1.2 1.2-.3 0-.6-.1-.8-.4-.2-.2-.3-.5-.3-.9 0-.6.5-1.2 1.2-1.2zm-1.6.6c-.5 1-1 2.1-1.5 3.4-.1.3-.2.5-.3.8 1.4-.6 2.8-1.1 4-1.8.5-.3.9-.6 1.1-1.1l1.5-3.3c.1-.3.2-.5.3-.8-.2.1-.5.2-.7.3-1.1.5-2.2 1-3.4 1.5-.5.2-.9.5-1 1" />
          </svg>
          <span>Umkreis auswählen</span>
        </button>
      </div>
    </>
  );

  return (
    <>
      {isOpen && <button className={`outside-click`} onClick={() => setIsOpen(false)} />}
      <form
        id="form-filters"
        onReset={(e) => {
          e.currentTarget.reset();
          // Manually reset the defaultValue of ReachUis Combobox
          (e.currentTarget.elements.namedItem("state") as HTMLInputElement).defaultValue = "";
          (e.currentTarget.elements.namedItem("country") as HTMLInputElement).defaultValue = "";
          setShowCircleSelection(false);
        }}
        onSubmit={(e) => e.preventDefault()}
        className={`${style.locationFilter}`}
      >
        <div className={style.legend}>
          <button
            className={`${style.filterBtn} ${isOpen ? style.filterOpen : style.filterClosed}`}
            onClick={() => setIsOpen((isOpen) => !isOpen)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              height="24"
              width="24"
            >
              <g>
                <path d="M0,0h24 M24,24H0" fill="none" />
                <path d="M4.25,5.61C6.57,8.59,10,13,10,13v5c0,1.1,0.9,2,2,2h0c1.1,0,2-0.9,2-2v-5c0,0,3.43-4.41,5.75-7.39 C20.26,4.95,19.79,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z" />
                <path d="M0,0h24v24H0V0z" fill="none" />
              </g>
            </svg>
            <span>Livepoints filtern</span>
          </button>
        </div>
        {IS_MOBILE && getWindowWidth() < 650 ? (
          <div className={`${style.content} ${isOpen ? style.show : style.hide}`}>{content}</div>
        ) : (
          <div className={`${style.content} ${isOpen ? style.show : style.hide} `}>{content}</div>
        )}
      </form>
    </>
  );
};

export default LocationFilter;
